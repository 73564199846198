import Vue from 'vue'

// axios
import axios from 'axios'

const token = `Bearer ${localStorage.getItem('accessToken')}`
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://system.api.kanjabung.my.id/',
  baseURL: 'https://system.api.dev.kanjabung.my.id/',
  timeout: 100000,
  headers: {
    'x-app': 'SIAP',
    'x-device-type': 'WebBrowser',
    'Content-Type': 'application/json',
    Authorization: token || null,
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
