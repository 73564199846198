import Vue from 'vue'
import { ValidationProvider, extend } from 'vee-validate'
import {
  // eslint-disable-next-line no-unused-vars
  required, email, confirmed, numeric, max, min, size, ext, max_value,
} from 'vee-validate/dist/rules'
import { password } from '@/@core/utils/validations/validations'

extend('confirmed', {
  ...confirmed,
  message: '{_field_} belum sesuai',
})

extend('max', {
  ...max,
  message: '{_field_} max 16 character',
})

extend('min', {
  ...min,
  message: '{_field_} min 6 character',
})

extend('email', {
  ...email,
  message: 'Pastikan format email sudah benar. Harus terdapat tanda “@“ dan jeda (.)',
})

extend('required', {
  ...required,
  message: 'Harap isi {_field_} terlebih dahulu',
})

extend('numeric', {
  ...numeric,
  message: '{_field_} must be filled with only numbers',
})

extend('size', {
  ...size,
  message: '{_field_} must be less than 3MB',
})

extend('ext', {
  ...ext,
  message: '{_field_} must be JPG/PNG',
})

extend('max_value', {
  // eslint-disable-next-line camelcase
  ...max_value,
  message: '{_field_} harus dibawah 100',
})

extend('password', {
  // validate: value => {
  //   const rule = /(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/
  //   return rule.test(value)
  // },
  ...password,
  message: 'Password minimal 8 karakter, terdiri dari angka, huruf besar dan huruf kecil',
})

Vue.component('validation-provider', ValidationProvider)
